<template>
  <a-modal
    width="450px"
    class="assignment-confirmation-modal"
    :dialog-style="{ display: 'none' }"
    :footer="null"
    :closable="true"
    :visible="active"
    @cancel="onExit">
    <h3 class="flex align-center txt-22 txt-bold">
      {{ $t('components.titles.reassignClusters') }}
    </h3>
    <p v-if="!accepted">
      {{ $t('components.description.actionMayIntroduceSelectionBias') }}
    </p>
    <p v-if="accepted">
      {{ $t('components.description.enterJustification') }}
    </p>
    <a-textarea v-if="accepted" v-model="justification"/>
    <div v-if="!accepted" class="flex justify-space-between w100">
      <a-button
        class="mt-24"
        type="primary" ghost
        size="large"
        @click="onExit">
        {{ $t('components.description.goBack') }}
      </a-button>
      <a-button
        class="mt-24"
        type="primary"
        size="large"
        @click="accepted = true">
        {{ $t('components.description.reassignClusters') }}
      </a-button>
    </div>
    <div v-else class="flex justify-space-between w100">
      <a-button
        class="mt-24"
        type="primary" ghost
        size="large"
        @click="onExit">
        {{ $t('values.cancel') }}
      </a-button>
      <a-button
        class="mt-24"
        type="primary"
        size="large"
        :disabled="justification === ''"
        @click="save">
        {{ $t('components.description.saveAndContinue') }}
      </a-button>
    </div>
  </a-modal>
</template>

<script>
export default {
  name: 'GeographicUnitModal',
  props: {
    active: {
      type: Boolean,
      required: true
    },
    onExit: {
      type: Function,
      required: true
    },
    onComplete: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      justification: '',
      accepted: false
    };
  },
  methods: {
    save() {
      this.onComplete(this.justification);
    }
  }
};
</script>

<style lang="scss" scoped>

</style>
